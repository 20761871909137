@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100vw;
  background-image: url(https://res.cloudinary.com/dvd0muryl/image/upload/v1610781792/backroundImage_xddz4x.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  justify-content: center;
  font-family: 'Bodoni Moda', serif;
  min-height: 100vh;
}

.Board {
  width: 50vw;
  background-color: rgba(194, 194, 194, 0.8);
}

button {
  font-family: 'Bodoni Moda', serif;
}

@media only screen and (max-width: 1200px) {
  .Board {
    width: 90vw;
  }
}

@media only screen and (max-width: 500px) {
  .Board {
    width: 100vw;
  }
}
.RecipesContainer {
    display: grid;
    margin: 60px;
    margin-top: 40px;
    grid-row-gap: 30px;
    row-gap: 30px;
}

@media only screen and (max-width: 500px) {
    .RecipesContainer {
        margin: 0px;
        margin-top: 30px;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

.Container {
    position: relative;
}

.NameContainer {
    text-align: center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(191, 191, 191);
}

.NameContainer:hover {
    opacity: 0.8;
    cursor: pointer;
}

.Text {
    color: black;
    font-size: 22px;
    margin: 0px;
    text-align: center;
}

.Image {
    width: 100%;
}

@media only screen and (min-width: 500px) {
    .Text {
        color: black;
        font-size: 30px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

.CustomCheckBox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .CustomCheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .CustomCheckBox:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .CustomCheckBox input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .CustomCheckBox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .CustomCheckBox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
.Filter {
    display: grid;
    justify-content: center;
}

.Filter input[type="text"] {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
    width: 35vw;
    font-size: 20px;
    padding-left: 15px;
}

.FiltersContainer {
    margin-top: 10px;
    box-sizing: border-box;
    width: 35vw;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
}

.Filter button {
    background-color: #4CAF50;
    color: white;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
    width: 65px;
    height: 40px;
    position: relative;
    top: -2px;
}

@media only screen and (max-width: 1500px) {
    .FiltersContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .Filter input[type="text"] {
        width: 75vw;
    }

    .FiltersContainer {
        grid-template-columns: 1fr;
        width: 75vw;
    }
}
.RecipeContainer {
    display: grid;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.RecipeImage {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.ImageContainer {
    position: relative;
}

.RecipeNameContainer {
    text-align: center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    background-color: rgb(191, 191, 191);
}
.RecipeName {
    font-size: 35px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RecipePart {
    margin-left: 20px;
}

.RecipeListItem {
    font-size: 21px;
}

h2 {
    font-size: 28px;
}

.BackButton {
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    transition: .5s ease;
    font-size: 18px;
    background-color: gray;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 5px;
}

.BackButton:hover {
    opacity: 0.9;
}

.AdminHeader {
    margin-bottom: 5px;
    padding: 0px;
}

.AdminContainer {
    display: grid;
    justify-content: left;
}

.AdminBlock {
    margin-left: 15px;
    padding-bottom: 10px;
}

.NameInput {
    padding: 12px 20px;
    margin: auto;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 300px;
    width: 25vw;
}

.InputParts {
    display: flex;
}

.InputPartBox {
    display: grid;
    justify-content: center;
    margin-right: 5px;
}

.StepBox {
    display: grid;
}

.InputPartInput {
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 25vw;
}

.StepInput {
    padding: 12px 20px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 35vw;
}

.SubmitButton {
    width: 200px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.AddButton {
    width: 100px;
    height: 45px;
    margin-top: 30px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.DeleteButton {
    width: 50px;
    height: 25px;
    margin-left: 15px;
    margin-bottom: 5px;
    background-color: #fa0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ObjectsList {
    display: flex;
}

.CheckBoxLabel {
    margin-bottom: 10px;
}

.Error {
    color: red;
}

.MoveButton {
    width: 30px;
    height: 25px;
    margin-left: 15px;
    margin-bottom: 5px;
    background-color: #a0a0a0;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .StepInput {
        width: 60vw;
    }
  }

.AuthContainer {
    margin-top: 25px;
     display: grid;
     justify-content: center;
     text-align: center;
}

.AuthContainer input {
    height: 30px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 8px;
}

.AuthContainer button {
    width: 200px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
}

.AuthContainer h3 {
    color: red;
}
