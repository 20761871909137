@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap');

html {
  scroll-behavior: smooth;
}

.App {
  width: 100vw;
  background-image: url(https://res.cloudinary.com/dvd0muryl/image/upload/v1610781792/backroundImage_xddz4x.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  justify-content: center;
  font-family: 'Bodoni Moda', serif;
  min-height: 100vh;
}

.Board {
  width: 50vw;
  background-color: rgba(194, 194, 194, 0.8);
}

button {
  font-family: 'Bodoni Moda', serif;
}

@media only screen and (max-width: 1200px) {
  .Board {
    width: 90vw;
  }
}

@media only screen and (max-width: 500px) {
  .Board {
    width: 100vw;
  }
}