.BackButton {
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    transition: .5s ease;
    font-size: 18px;
    background-color: gray;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 5px;
}

.BackButton:hover {
    opacity: 0.9;
}
