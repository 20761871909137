.Filter {
    display: grid;
    justify-content: center;
}

.Filter input[type="text"] {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
    width: 35vw;
    font-size: 20px;
    padding-left: 15px;
}

.FiltersContainer {
    margin-top: 10px;
    box-sizing: border-box;
    width: 35vw;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
}

.Filter button {
    background-color: #4CAF50;
    color: white;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
    width: 65px;
    height: 40px;
    position: relative;
    top: -2px;
}

@media only screen and (max-width: 1500px) {
    .FiltersContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .Filter input[type="text"] {
        width: 75vw;
    }

    .FiltersContainer {
        grid-template-columns: 1fr;
        width: 75vw;
    }
}