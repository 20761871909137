.RecipesContainer {
    display: grid;
    margin: 60px;
    margin-top: 40px;
    row-gap: 30px;
}

@media only screen and (max-width: 500px) {
    .RecipesContainer {
        margin: 0px;
        margin-top: 30px;
        row-gap: 20px;
    }
}
