.AuthContainer {
    margin-top: 25px;
     display: grid;
     justify-content: center;
     text-align: center;
}

.AuthContainer input {
    height: 30px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 8px;
}

.AuthContainer button {
    width: 200px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: auto;
}

.AuthContainer h3 {
    color: red;
}