.Container {
    position: relative;
}

.NameContainer {
    text-align: center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(191, 191, 191);
}

.NameContainer:hover {
    opacity: 0.8;
    cursor: pointer;
}

.Text {
    color: black;
    font-size: 22px;
    margin: 0px;
    text-align: center;
}

.Image {
    width: 100%;
}

@media only screen and (min-width: 500px) {
    .Text {
        color: black;
        font-size: 30px;
        position: absolute;
        top: 45%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }
}
