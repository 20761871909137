.AdminHeader {
    margin-bottom: 5px;
    padding: 0px;
}

.AdminContainer {
    display: grid;
    justify-content: left;
}

.AdminBlock {
    margin-left: 15px;
    padding-bottom: 10px;
}

.NameInput {
    padding: 12px 20px;
    margin: auto;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 300px;
    width: 25vw;
}

.InputParts {
    display: flex;
}

.InputPartBox {
    display: grid;
    justify-content: center;
    margin-right: 5px;
}

.StepBox {
    display: grid;
}

.InputPartInput {
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 25vw;
}

.StepInput {
    padding: 12px 20px;
    margin-right: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 35vw;
}

.SubmitButton {
    width: 200px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.AddButton {
    width: 100px;
    height: 45px;
    margin-top: 30px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.DeleteButton {
    width: 50px;
    height: 25px;
    margin-left: 15px;
    margin-bottom: 5px;
    background-color: #fa0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ObjectsList {
    display: flex;
}

.CheckBoxLabel {
    margin-bottom: 10px;
}

.Error {
    color: red;
}

.MoveButton {
    width: 30px;
    height: 25px;
    margin-left: 15px;
    margin-bottom: 5px;
    background-color: #a0a0a0;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .StepInput {
        width: 60vw;
    }
  }
