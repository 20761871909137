.RecipeContainer {
    display: grid;
    row-gap: 15px;
}

.RecipeImage {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.ImageContainer {
    position: relative;
}

.RecipeNameContainer {
    text-align: center;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.6;
    background-color: rgb(191, 191, 191);
}
.RecipeName {
    font-size: 35px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RecipePart {
    margin-left: 20px;
}

.RecipeListItem {
    font-size: 21px;
}

h2 {
    font-size: 28px;
}
